import Cookies from "js-cookie"
import router, { resetRouter } from '@/router'
import { login, wwlogin, getInfo, logout, fslogin, fsloginMobile } from '@/api/user'
import Layout from '@/layout'

const MyTokenKey = 'my_vue_admin_token'
const MyServiceModeKey = 'my_vue_admin_service_mode'

export function getToken() {
  return Cookies.get(MyTokenKey)
}

export function removeToken() {
  return Cookies.remove(MyTokenKey)
}

export function setToken(token) {
  return Cookies.set(MyTokenKey, token, { expires: 5 })
}

export function setServiceMode(serviceMode) {
  return Cookies.set(MyServiceModeKey, serviceMode, {expires: 5})
}

let getinfo_data = null

const state = {
  token: getToken(),
  avatar: '',
  name: '',
  tdl_view_type: '',
  first_route_path: '',
  routes: [],
  menus: [],
  department: [],
  dynamic_auth : [],
  dist_timestamp: null,
  pcRouterConfig: null,
  serviceMode: 'normal', // 商务业务模式：常规业务，签约业务
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_TDL_VIEW_TYPE: (state, tdl_view_type) => {
    state.tdl_view_type = tdl_view_type
  },
  SET_ROUTES: (state, routes) => {
    state.routes = routes
  },
  SET_MENUS: (state, menus) => {
    state.menus = menus
  },
  SET_DEPARTMENT: (state, department) => {
    state.department = department
  },
  SET_FIRST_ROUTE_PATH: (state, first_route_path) => {
    state.first_route_path = first_route_path
  },
  SET_FIRST_DYNAMIC_AUTH: (state, dynamic_auth) => {
    state.dynamic_auth = dynamic_auth
  },
  SET_DIST_TIMESTAMP: (state, dist_timestamp) => {
    state.dist_timestamp = dist_timestamp
  },
  SET_PC_ROUTER_CONFIG: ( state, pc_router_config) => {
	  state.pcRouterConfig = pc_router_config
  },
  SET_SERVICE_MODE: (state, serviceMode) => {
    state.serviceMode = serviceMode
  }
}

const actions = {
  // 用户登录
  login({commit}, userInfo) {
    console.log(userInfo)
    const { username, password } = userInfo

    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password }).then(response => {
        const { data } = response
        commit('SET_TOKEN', data.token)
        setToken(data.token)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  wwlogin({commit}, code) {
    console.log('code', code)
    return new Promise((resolve, reject) => {
      wwlogin({ code }).then(response => {
        const { data } = response
        commit('SET_TOKEN', data.token)
        setToken(data.token)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  fslogin({commit}, code) {
    console.log('code', code)
    return new Promise((resolve, reject) => {
      fslogin({ code }).then(response => {
        const { data } = response
        commit('SET_TOKEN', data.token)
        setToken(data.token)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  fsloginMobile({commit}, code) {
    console.log('code', code)
    return new Promise((resolve, reject) => {
      fsloginMobile({ code }).then(response => {
        const { data } = response
        commit('SET_TOKEN', data.token)
        setToken(data.token)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      if(getinfo_data === null) {
        getInfo(state.token).then(response => {
          const { data } = response

          if (!data) {
            reject('校验失败，请重新登录.')
          }

          const { routes, name, avatar, tdl_view_type, adm_menus, first_route_path, department, dynamic_auth, pcRouterConfig} = data
          getinfo_data = data
          // roles must be a non-empty array
          if (!routes || routes.length <= 0) {
            commit('SET_TOKEN', '')
            commit('SET_ROUTES', [])
            removeToken()
            reject('用户还没有分配任何权限!')
          }

          // commit('SET_ROUTES', routes)
		  
          commit('SET_NAME', name)
          commit('SET_AVATAR', avatar)
		  commit('SET_TDL_VIEW_TYPE', tdl_view_type)
          commit('SET_MENUS', adm_menus)
          commit('SET_DEPARTMENT', department)
          commit('SET_FIRST_ROUTE_PATH', first_route_path)
          commit('SET_FIRST_DYNAMIC_AUTH', dynamic_auth)
		  
		  commit('SET_PC_ROUTER_CONFIG', pcRouterConfig)
		  
          resolve(data)
        }).catch(error => {
          reject(error)
        })
      } else {
        resolve(getinfo_data)
      }
    })
  },

  setRoutes({commit, state}, newroutes) {
    return new Promise((resolve, reject) => {
      commit('SET_ROUTES', newroutes)
      resolve()
    })
  },

  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROUTES', [])
      removeToken()
      resolve()
    })
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      logout().then(response => {
        commit('SET_TOKEN', '')
        commit('SET_ROUTES', [])
        removeToken()
        resetRouter()

        // reset visited views and cached views
        // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
        dispatch('tagsView/delAllViews', null, { root: true })

        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  setDistTimeStamp({commit, state}, dist_timestamp) {
    return new Promise((resolve, reject) => {
      commit('SET_DIST_TIMESTAMP', dist_timestamp)
      resolve()
    })
  },

  setServiceMode({commit, state}, serviceMode) {
    return new Promise((resolve, reject) => {
      if(serviceMode != 'normal' && serviceMode != 'sign') {
        serviceMode = 'normal'
      }
      commit('SET_SERVICE_MODE', serviceMode)
      setServiceMode(serviceMode)
      resolve()
    })
  }
}

export function setUserGetInfoData(val = null)
{
  getinfo_data = val
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
