import router from './router'
import store from './store'
import Cookies from "js-cookie"
import NProgress from 'nprogress' // progress bar
import Layout from '@/layout'
import { Message, MessageBox } from 'element-ui'
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from './store/module/user'
import settings from '@/store/module/settings'

const MyServiceModeKey = 'my_vue_admin_service_mode'

const title = settings.state.title

export function getPageTitle(pageTitle) {
  if (pageTitle) {
    return `${pageTitle} - ${title}`
  }
  return `${title}`
}

const allowedRoutes = [
  'admin/index',
  'admin/clearCache',
  'menu/index',
  'menu/AddMenu',
  'member/index',
  'member/addMember',
  'Authorize/index',
  'Authorize/addGroup',
  'department/index',
  'customer/index',
  'Task/index',
  'Task/details',
  'customerFollow/index',
  'talentAccount/index',
  'advertiseOrder/index',
  'advertiseOrder/revenue_stat',
  'advertiseOrder/logList',
  'staff/index',
  'staff/updateStaff',
  'staff/staffData',
  'clothing/index',
  'clothing/updateUser',
  'clothing/restrictMakeup',
  'clothing/restrictShack',
  'clothing/addUser',
  'clothing/aggregate',
  'clothing/monthCollect',
  'accountSchedule/index',
  'dynamic/tagsList',
  'dynamic/taskIndex',
  'dynamic/targetReplay',
  'dynamic/setUp',
  'dynamic/statCheck',
  'dynamic/newGuide',
  'purchase/index',
  'purchase/order',
  'assets/index',
  'staff/attendance',
  'Register/index',
  'InventoryDetails/index',
  'assets/select',
  'Todolist/index',
  'dynamic/meeting',
  'penguin/qehUser',
  'penguin/qehEarnings',
  'penguin/qehContent',
  'penguin/qehAssets',
  'penguin/czhUser',
  'penguin/czhEarnings',
  'workTable/index',
  'assets/phone',
  'staff/identity',
  'scheduling/index',
  'scheduling/listView',
  'dynamic/pointIndex',
  'demand/index',
  'feedback/index',
  'about/shedList',
  'about/summaryShed',
  'about/clothing',
  'about/summaryClothing',
  'about/aboutMember',
  'about/deptMember',
  'about/summaryCamera',
  'about/summaryRentingHouse',
  'about/blackMember',
  'content/account',
  //'content/index',
  'content/index_data',
  'content/day_data',
  'content/count',
  'content/account_stat',
  'business/statistics',
  'business/access_log',
  'movieAccount/index',
  'movieAccount/count',
  'movieAccount/index_data',
  'dynamic/targetIndex',
  'dynamic/planIndex',
  'dynamic/experienceNew',
  'staff/candidate',
  'staff/candidateTalent',
  'staff/candidate_data',
  'staff/statistic',
  'put/account_index',
  'put/calendar_index',
  'put/consume_index',
  'put/statistics',
  'assets/statistics',
  'Live/Acc',
  'Live/AccOrder',
  'Live/Material',
  'Live/AccStat',
  'Live/DirectOrder',
  'Live/AccOrderSubscribe',
  'Live/AccOrderSubWrapper',
  'Live/AccOrderWrapper',
  'Live/components/createLivePlan',
  'Live/AccVideo',
  'Live/AccDirect',
  'Live/StopStrategy',
  'Live/LivePlan',
  'Live/VideoPlan',
  'product/index_list',
  'lovebox/index',
  'cameraman/score',
  'makeup/score',
  'dress/score',
  'platforms/apply',
  'platforms/apply_manage',
  'income/commission',
  'income/senduser',
  'interpolation/publish',
  'interpolation/record',
  'interpolation/dynamic',
  'interpolation/statistic',
  'interpolation/rankList',
  'bookkeeping/index',
  'productlibrary/index',
  'put/doujia',
  'material_library/index',
  'finance/index',
  'finance/manageIndex',
  'finance/financeIndex',
  'finance/bossIndex',
  'finance/financeManageIndex',
  'finance/cashierIndex',
  'design/index',
  'wechatVideoAccount/acc',
  'wechatVideoAccount/works',
  'contentWorkOrder/content_work_order',
  'contentWorkOrder/clip_confirm_order',
  'contentWorkOrder/clip_order_list',
  'contentWorkOrder/clip_user_group',
  'contentWorkOrder/work_schedule',
  'Performance/Account',
  'Performance/CommerceDay',
  'Performance/CommerceMonth',
  'finance/data_stat',
  'Performance/CommerceMonth',
  'Performance/CommerceMonth',
  'RedBook/index',
  'RedBook/fans',
  'Live/ActivityStatistics',
  'kuaishou/account',
  'kuaishou/video',
  'newassets/assets',
  'newassets/register',
  'newassets/data_stat',
]

function addNewRoutes(newroutes, routes) {
  let newroute = {
    id: 0,
    path: '/',
    component: Layout,
    meta: { title: '后台首页' },
  }
  if(routes.length > 0) {
    newroute['children'] = []
    
    for (let k in routes) {
      let rout_p = allowedRoutes.indexOf(routes[k]['name']) !== -1 ? routes[k]['name'] : 'admin/empty'
      let tmp = import('@/views/' + rout_p)
      
      newroute['children'].push({
        id: routes[k]['id'],
        path: '/' + routes[k]['name'],
        name: 'id' + routes[k]['id'],
        component: () => tmp,
        meta: { title: routes[k]['title'], icon: routes[k]['icon'], affix: false },
      })
    }
  }
  newroutes.push(newroute)
}

function checkRouteExists(newroutes, path) {
  for (let k in newroutes) {
    let newroute = newroutes[k]
    if (path === newroute['path']) {
      return true
    }
    else if (newroute['children']) {
      for (let kk in newroute['children']) {
        let children = newroute['children'][kk]
        if(children['path'] === path) {
          return true
        }
      }
    }
  }
  return false
}

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/admin/login', '/admin/wwlogin', '/admin/fslogin', '/admin/fsmdlogin'] // no redirect whitelist

let getinfo_data = false

let global_first_route = '/admin/index'

let route_dist_timestamp = null

router.beforeEach(async(to, from, next) => {
  
  NProgress.start()

  document.title = getPageTitle(to.meta.title)

  const hasToken = getToken()

  if (hasToken) {
    if (to.path === '/admin/login' || to.path === '/admin/wwlogin' || to.path === '/admin/fslogin' || to.path === '/admin/fsmdlogin') {
      next({ path: global_first_route })
      NProgress.done()
    }
    else {
      const hasRoutes = store.getters.routes && store.getters.routes.length > 0
      // console.log('store.getters.routes:', store.getters.routes)
      if(hasRoutes) {
        // console.log('hasRoutes', hasRoutes)
        if(route_dist_timestamp === null) {
          route_dist_timestamp = store.getters.dist_timestamp
        }
        else if(route_dist_timestamp !== store.getters.dist_timestamp) {
          MessageBox.alert('检测到新版本，点确定刷新当前浏览器', '新版本提示', {
            callback: action => {
              window.location.reload()
            }
          })
        }

        /* if(to.path === '/') {
          if(store.getters.first_route_path) {
            console.log(store.getters.first_route_path)
            window.location.href = '/#' + store.getters.first_route_path
            window.location.reload()
            // next({path: store.getters.first_route_path, replace: true})
            // NProgress.done()
          }
        }
        else  */
        if(to.path === '/') {
          window.location.reload()
        } else if(to.path == '/admin/index' && global_first_route != '/admin/index') {
          next({path: global_first_route, replace: true})
        } else {
          next()
        }
      } else if (!getinfo_data) {
        try {
          const { routes, first_route_path, pcRouterConfig } = await store.dispatch('user/getInfo')
		  
		  let pathname = window.location.pathname
		  if ( (process.env.NODE_ENV === 'production') && pathname != (`/${pcRouterConfig.path}${pcRouterConfig.version}/`)) {
            MessageBox.alert('检测到新版本，点确定刷新当前浏览器', '新版本提示', {
              callback: action => {
				let domain = pcRouterConfig.domain
				let currentUrl = `${domain}${pcRouterConfig.path}${pcRouterConfig.version}/#` + to.path
                window.location.href = currentUrl
              }
            })
		  }

          global_first_route = first_route_path

          let newroutes = []
          addNewRoutes(newroutes, routes)

          //console.log('newrouters:', newroutes)
          router.addRoutes(newroutes)
          router.options.routes.push(...newroutes)
          //console.log('router.options.routes:', router.options.routes)
          await store.dispatch('user/setRoutes', newroutes)

          getinfo_data = true
          /* if(newroutes.length > 0 && !checkRouteExists(newroutes, to.path)) {
            next({path: newroutes[0]['path'], replace: true})
          } */

          let cookie_service_mode = Cookies.get(MyServiceModeKey)
          await store.dispatch('user/setServiceMode', cookie_service_mode)

          if ( (from.path === '/admin/login' || from.path === '/admin/fslogin' || from.path === '/admin/fsmdlogin') && to.path === '/admin/index' ) {
            console.log('first_route_path: ', first_route_path)
            next({path: first_route_path, replace: true})
          }
          else if(to.path === '/') {
            next({path: first_route_path, replace: true})
          }
          else {
            next({ ...to, replace: true })
            // console.log('else: next')
            // next('/')
          }

        } catch (error) {
          // await store.dispatch('user/resetToken')
          Message.error(error || 'Has Error')
          // next(`/admin/login?redirect=${to.path}`)
          NProgress.done()
        }
      } else {
        Message.error('未获取到权限信息')
        NProgress.done()
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/admin/fslogin?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  //console.log('router.afterEach')
  // finish progress bar
  NProgress.done()
})

export function setGetInfoDataValue(val = false)
{
  getinfo_data = val
}
