import Vue from 'vue'
import App from './App.vue'
import router from './router'

import 'normalize.css/normalize.css' // A modern alternative to CSS resets

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import '@/styles/index.scss' // global css

import '@/icons' // icon
import '@/permission' // permission control

import store from '@/store'

import '@/rem' // rem

Vue.use(ElementUI)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  data() {
    return {
      totalScore: 0,
      needRefreshTargetData: 0, // 是否需要刷新目标相关列表，关系图等，通过监听方式
      needRefreshWorktable: 0, // 是否需要刷新工作台
      isUseZoom: false, // 是否使用了zoom
	    needRefreshMessage:0,
    }
  },
  render: h => h(App),
}).$mount('#app')
