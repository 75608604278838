import axios from "axios"
import store from "@/store"
import { Message } from 'element-ui'

let baseUrl = ''
baseUrl = process.env.VUE_APP_API_BASE_URL

let service = axios.create({
  baseURL: baseUrl, 
  timeout: 60 * 5 * 1000, // request timeout
  headers: {
    'Content-Type':'application/x-www-form-urlencoded'
  }
})

service.getBaseURL = () => {
  return baseUrl
}

service.interceptors.request.use(
  config => {
    if ( store.getters.token) {
      config.headers['X-Token'] = store.getters.token
    }
    if (store.getters.service_mode) {
      config.headers['X-Service-Mode'] = store.getters.service_mode
    }
    return config
  }
)

service.interceptors.response.use(
  response => {
    // console.log(response)
    const res = response.data
    if (res.code !== 0) {
      if(res.code == -6) { // 如果是无效的token，则清理缓存的token，然后刷新当前页面
        store.dispatch('user/resetToken').then((response) => {
          window.location.reload()
        }).catch(error => {
        })
      }

      Message({
        message: res.msg || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
      
      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      if(res.timestamp.dist_timestamp && store.getters.dist_timestamp !== res.timestamp.dist_timestamp) {
        store.dispatch('user/setDistTimeStamp', res.timestamp.dist_timestamp)
      }
      return res
    }
  },
  error => {
    // console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
